import React from "react"

const Dashboard = () => {

    const HEADER = ({children}) => (
        <div className="card-header">
            <h2>
                {children}
            </h2>
        </div>
    )

    const TABLE = ({children}) => (
        <div className="table-wrapper">
            <table>
                <thead>
                    <tr className="mainheader">
                        <th>Month</th>
                        <th>Year</th>
                        <th>Impressions</th>
                        <th>Clicks</th>
                        <th>Avg. CTR</th>
                        <th>Avg. CPC</th>
                        <th>Avg. OV</th>
                        <th>Orders</th>
                        <th>Conversion Rate</th>
                        <th>Advertising Cost</th>
                        <th>Total Sales</th>
                        <th>Profit</th>
                        <th>ACoS</th>
                        <th>RoAS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="14">
                            <p className="text-center bold">No Statistics Available</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )

    const DATA = [
        {}
    ]

    return (
        <React.Fragment>
            <main id="statistics-page">
                <section>
                    <HEADER>
                        <strong>Last 0 Months</strong> Premium-quality - Amazon US
                    </HEADER>
                    <TABLE/>
                </section>
                <section>
                    <HEADER>
                        <strong>Last 16 Months</strong> Huididi - Amazon DE
                    </HEADER>
                    <TABLE/>
                </section>
                <section>
                    <HEADER>
                        <strong>Last 21 Months</strong> Hustle Co - Amazon UK
                    </HEADER>
                    <TABLE/>
                </section>
            </main>
        </React.Fragment>
    )
}

export default Dashboard