import React from "react"
import Seo from "src/utils/seo"
import Layout from "components/Layout/Layout"
import Statistics from "components/Statistics/Statistics"

export default function Home() {
    return(
        <React.Fragment>
            <Seo title="Account Statistics" />
            <Layout showActions={false}>
                <Statistics/>
            </Layout>
        </React.Fragment>
    )
}